<template>
    <div class="defeat-from">
      <van-form ref="formRef" :show-error="false">
        <van-field v-model="form.result" required :name="$t('审核结果')" :label="$t('审核结果')"
          :rules="[{ required: true, message: $t('请选择审核结果') }]">
          <template #input>
            <van-radio-group v-model="form.result" direction="horizontal">
              <van-radio name="10">{{ '驳回' }}</van-radio>
              <van-radio name="5">{{ '同意' }}</van-radio>
            </van-radio-group>
          </template>
        </van-field>
    
        <van-field v-if="form.result == 10" 
            v-model="form.refuseReason" 
            :required="form.result == 10" 
            style="display: block;" 
            type="textarea" 
            :name="$t('驳回原因')" 
            :label="$t('驳回原因')"
            :placeholder="$t('请输入')" 
            :rules="[{ required: form.result == 10, message: $t('请输入') }]" 
            maxlength="200" show-word-limit />
      </van-form>
    </div>
    </template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      form: {
        result: '',
        refuseReason: '',
      },
      dictList: [
        {
          code: 10,
          name: '驳回'
        },
        {
          code: 5,
          name: '同意'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    
    async validate() {
      return this.$refs.formRef.validate()
    }
  }
}
</script>

<style lang="less" scoped>
.defeat-from {
  padding: 20px;
}
</style>
