<template>
    <div class="taskManage">
        <div class="taskManage-l">
            <div v-for="(item,index) in tabs" :key="index" :class="[type === item.value?'current':'','type-item']" @click="changeTab(item)">{{ item.name }}({{ item.num }})</div>
        </div>
        <div class="taskManage-r">
            <List ref="list" :fetchApi="fetchApi" class="task-manage-list">
            <template v-slot:default="{ item, index }">
                <Card :info="item" @click="toDetails(item)" @change="onCheckChange"/>
            </template>
            </List>
            <div class="oper-bar">
                <div class="left">
                    <van-checkbox
                        v-model="isAll"
                        shape="square"
                        class="check-item">
                        {{$t('全选')}}
                    </van-checkbox>
                </div>
                <div class="right">
                    <van-button square color="#EED484" @click="onDistribution">
                        {{$t('批量审核')}}{{ selectIds.length ? `(${selectIds.length})` : '' }}
                    </van-button>
                </div>
            </div>
        </div>
        <van-dialog v-model="isShowReview" :title="''" 
            show-cancel-button 
            :confirm-button-text="$t('确认')" 
            :cancel-button-text="$t('取消')"
            :before-close="onReviewConfirm"
            @cancel="isShowReview=false">
            <reviewForm v-if="isShowReview" ref="reviewForm"/>
        </van-dialog>
    </div>
  </template>
  
<script>
import Card from './components/card.vue'
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import { v4 as uuidv4 } from 'uuid'
import { taskStatistic,taskListFetch } from '@/services/taskManage.js'
import { mapGetters } from 'vuex'
import { maxDate, minDate } from '../clue/config'
import dayjs from 'dayjs'
import reviewForm from './components/reviewForm.vue'
import deliveryServices from '@/services/deliveryServices.js'
export default {
  components:{ Card,List,reviewForm },
  data() {
    const that = this
    return {
      type: 1,
      tabs:[
        {
          name: this.$t('线下确认书签署'),
          value: 1,
          get num() {
            return that.countInfo[1] || 0
          }
        },
        {
          name: this.$t('质损交车'),
          value: 2,
          get num() {
            return that.countInfo[2] || 0
          }
        },
        // {
        //   name: '异常订单',
        //   value: 3,
        //   get num() {
        //     return that.countInfo[3] || 0
        //   }
        // },
      ],
      countInfo:{},
      selectIds: [],
      isAll: false,
      isShowReview: false,
      reviewFormData: {}
    }
  },
  watch: {
    isAll: {
      handler(val){
        if (val) this.onAllChecked()
        else this.cleanDistribution()
      }
    }
  },
  mounted(){
    this.taskCount()
  },
  methods:{
    onCheckChange(checked,theItem){
      const { id } = theItem
      const ids = []
      this.$refs.list.list.forEach(item=>{
        if (item.id === id){
          item.checked=checked
        }
        if (item.checked) ids.push(item.id)
      })
      this.selectIds = ids
    },
    async taskCount(){
      const temp = {}
      const res = await deliveryServices.errorApproveCount({})
      res.forEach(e => {
        temp[e.type] = e.count
      })
      this.$set(this,'countInfo',temp)
    },
    fetchApi({ currentPage,pageSize }) {
      const params = {
        pageNum: currentPage,
        pageSize: pageSize,
        type: this.type,
        statuses: [1]
      }
      loading.showLoading()
      return deliveryServices.errorApproveList(params).then((res) => {
        loading.hideLoading()
        res.dataList.forEach((item) => {
          item.checked = false
        })
        return res
      })
    },
    changeTab(typeItem){
      this.type = typeItem.value
      this.$refs.list.onRefresh()
    },
    toDetails(item){
      this.$router.push({
        path: '/abnormalProcessApprove/details',
        query: {
          isReject: false,
          type: this.type,
          id: item.id,
          orderId: item.order.id, // 交车单id
          attachUrl: item.attachUrl,
          displayStatus: 3
        }
      })
    },
    // 取消全选
    cleanDistribution(){
      this.selectIds = []
      this.$refs.list.list.forEach(item=>{
        item.checked = false
      })
    },
    // 全选
    onAllChecked(){
      const ids = []
      this.$refs.list.list.forEach(item=>{
        item.checked = true
        ids.push(item.id)
      })
      this.selectIds = ids
    },
    onDistribution(){
      if (this.selectIds.length === 0){
        this.$toast.fail('请选择要审核的数据')
        return
      }
      this.isShowReview = true
    },
    onReviewConfirm(action, done){
      if (['cancel','overlay'].includes(action)){
        done()
        return
      }
      this.$refs.reviewForm.validate().then(async ()=>{
        let { refuseReason,result } = this.$refs.reviewForm.form
        result = Number(result)
        const params = {
          ids: this.selectIds,
          result,
          refuseReason
        }
        const res = await deliveryServices.errorApproveReview(params)
        this.$toast(this.$t('操作成功'))
        this.selectIds = []
        const vm = this
        setTimeout(()=>{
          done()
          vm.taskCount()
          vm.$refs.list.onRefresh()
        },500)
      }).catch(error=>{
        done(false)
      })
    }
  }
}
</script>
  
  <style lang="less" scoped>
  @head-height: 48px;
  .taskManage{
    display: flex;
    .taskManage-l{
      font-size: 14px;
      width: 100px;
      border-right: 2px solid #fff;
      
      .type-item{
        cursor: pointer;
        padding: 12px;
      }
      .current{
        color: #B9921A;
        background: #fff;
      }
    }
    .taskManage-r{
        position: relative;
        width: calc(100% - 100px);
        .task-manage-list{
            /deep/.ul{
                padding: 10px;
            }
        }
        .oper-bar{
            position: fixed;
            width: calc(100% - 100px);
            max-width: 400px;
            bottom: 0;
            padding-bottom: env(safe-area-inset-bottom);
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            padding-left: 12px;
        }
    }
  }
  </style>
  